import { createContext, useState, useEffect } from 'react';
import { doc, onSnapshot, query, collection, orderBy, limit, getCountFromServer, getDocFromServer } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import firebase from './firebase';

export const AuthContext = createContext();

const AuthProvider = (props) => {
    const [userData, setUserData] = useState(null);
    const [notifications, setNotifications] = useState(null);

    const [userPictures, setUserPictures] = useState(null);
    const [accountPictures, setAccountPictures] = useState(null);

    const [accountData, setAccountData] = useState(null);
    const [accessTo, setAccessTo] = useState(null);

    const [adminData, setAdminData] = useState(null);

    const [shoppingCart, setShoppingCart] = useState(null);
    const [shift, setShift] = useState(null);

    const [authorizedUsers, setAuthorizedUsers] = useState(null);

    const [invoiceTemplate, setInvoiceTemplate] = useState(null);

    const [customers, setCustomers] = useState(null);
    const [customersStatistics, setCustomersStatistics] = useState(null);

    const [suppliers, setSuppliers] = useState(null);
    const [suppliersStatistics, setSuppliersStatistics] = useState(null);

    const [sales, setSales] = useState(null);
    const [salesStatistics, setSalesStatistics] = useState(null);

    const [purchases, setPurchases] = useState(null);
    const [purchasesStatistics, setPurchasesStatistics] = useState(null);

    const [products, setProducts] = useState(null);
    const [productsStatistics, setProductsStatistics] = useState(null);

    const getKeyInIndexedDB = (dbId) => {
        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-store")) {
                db.createObjectStore("hazcuentas-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-store', 'readwrite');

            transaction.onerror = (err) => { console.warn(err); }

            const store = transaction.objectStore('hazcuentas-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    switch (dbId) {
                        case ("userData"):
                            setUserData(request.result.key);
                            break;
                        case ("notifications"):
                            setNotifications(request.result.key);
                            break;
                        case ("userPictures"):
                            setUserPictures(request.result.key);
                            break;
                        case ("accountPictures"):
                            setAccountPictures(request.result.key);
                            break;
                        case ("accountData"):
                            setAccountData(request.result.key);
                            break;
                        case ("accessTo"):
                            setAccessTo(request.result.key);
                            break;
                        case ("customers"):
                            setCustomers(request.result.key);
                            break;
                        case ("customersStatistics"):
                            setCustomersStatistics(request.result.key);
                            break;
                        case ("suppliers"):
                            setSuppliers(request.result.key);
                            break;
                        case ("suppliersStatistics"):
                            setSuppliersStatistics(request.result.key);
                            break;
                        case ("products"):
                            setProducts(request.result.key);
                            break;
                        case ("productsStatistics"):
                            setProductsStatistics(request.result.key);
                            break;
                        case ("sales"):
                            setSales(request.result.key);
                            break;
                        case ("salesStatistics"):
                            setSalesStatistics(request.result.key);
                            break;
                        case ("purchases"):
                            setPurchases(request.result.key);
                            break;
                        case ("purchasesStatistics"):
                            setPurchasesStatistics(request.result.key);
                            break;
                        case ("adminData"):
                            setAdminData(request.result.key);
                            break;
                        case ("shoppingCart"):
                            setShoppingCart(request.result.key);
                            break;
                        case ("shift"):
                            setShift(request.result.key);
                            break;
                        case ("authorizedUsers"):
                            setAuthorizedUsers(request.result.key);
                            break;
                        case ("invoiceTemplate"):
                            setInvoiceTemplate(request.result.key);
                            break;
                        default:
                            break;
                    }
                }
            }
        }
    }

    // This prevent the page send a request twice to the backend
    const [generalState] = useState("0000");

    useEffect(() => {
        getKeyInIndexedDB("accountData");

        getKeyInIndexedDB("userPictures");
        getKeyInIndexedDB("accountPictures");

        getKeyInIndexedDB("userData");
        getKeyInIndexedDB("notifications")
        getKeyInIndexedDB("accessTo");

        getKeyInIndexedDB("adminData");

        getKeyInIndexedDB("customers");
        getKeyInIndexedDB("customersStatistics");

        getKeyInIndexedDB("suppliers");
        getKeyInIndexedDB("suppliersStatistics");

        getKeyInIndexedDB("sales");
        getKeyInIndexedDB("salesStatistics");

        getKeyInIndexedDB("purchases");
        getKeyInIndexedDB("purchasesStatistics");

        getKeyInIndexedDB("products");
        getKeyInIndexedDB("productsStatistics");

        getKeyInIndexedDB("shoppingCart");
        getKeyInIndexedDB("shift");

        getKeyInIndexedDB("authorizedUsers");
        getKeyInIndexedDB("invoiceTemplate");

    }, [generalState]);

    const accessToken = JSON.parse(localStorage.getItem('accessToken'));
    const isEmailVerified = JSON.parse(localStorage.getItem('isEmailVerified'));
    const isAccountSelected = JSON.parse(localStorage.getItem('isAccountSelected'));
    const isAdminAccess = JSON.parse(localStorage.getItem('isAdminAccess'));
    const isManagerAccess = JSON.parse(localStorage.getItem('isManagerAccess'));

    const [user, setUser] = useState(null);
    const [auth, setAuth] = useState(accessToken ? true : false);
    const [emailVerified, setEmailVerified] = useState(isEmailVerified ? true : false);
    const [accountSelected, setAccountSelected] = useState(isAccountSelected ? isAccountSelected : false);
    const [adminAccess, setAdminAccess] = useState(isAdminAccess ? isAdminAccess : null);
    const [managerAccess, setManagerAccess] = useState(isManagerAccess ? isManagerAccess : null);

    useEffect(() => {
        if (userData) {
            if (userData.businessAccounts) {
                if (userData.businessAccounts.length > 0) {
                    if (userData.businessAccounts[0].doc === "manager") {
                        setManagerAccess(true);
                        localStorage.setItem("isManagerAccess", JSON.stringify(true));
                    } else {
                        setManagerAccess(false);
                        localStorage.setItem("isManagerAccess", JSON.stringify(false));
                    }
                }
            }
        }
    }, [userData]);

    useEffect(() => {
        if (accountData && userData) {
            if (accountData.admin === userData.id) {
                setAdminAccess(true);
                localStorage.setItem("isAdminAccess", JSON.stringify(true));
            } else {
                setAdminAccess(false);
                localStorage.setItem("isAdminAccess", JSON.stringify(false));
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    const deleteAllInformation = () => {
        setUser(false);
        setUserData(false);
        setNotifications(null);

        setUserPictures(false);
        setAccountPictures(false);

        setAuth(false);
        setEmailVerified(false);
        setAccountSelected(false);
        setAccountData(false);

        setAdminAccess(false);
        setManagerAccess(false);

        setShoppingCart(null);
        setShift(null);

        setAuthorizedUsers(null);

        setInvoiceTemplate(null);

        setCustomers(null);
        setCustomersStatistics(null);

        setSuppliers(null);
        setSuppliersStatistics(null);

        setProducts(null);
        setProductsStatistics(null);

        setSales(null);
        setSalesStatistics(null);

        setPurchases(null);
        setPurchasesStatistics(null);

        localStorage.removeItem("accessToken");
        localStorage.removeItem("isEmailVerified");
        localStorage.removeItem("isAccountSelected");
        localStorage.removeItem("isAccounts");
        localStorage.removeItem("isPicture");
        localStorage.removeItem("appVisibility");
        localStorage.removeItem("isAdminAccess");
        localStorage.removeItem("isManagerAccess");
        localStorage.removeItem("toBeDeleted");
        localStorage.removeItem("isSideMenuMinimized");
        localStorage.removeItem("itemsDeleted");
        localStorage.removeItem("isProductsView");

        localStorage.removeItem("isLabelSettings");

        // Deleting the indexedDB with the auth key
        indexedDB.deleteDatabase("WebAuthnKeyId");

        // Deletind the indexedDB for hascuenta-database
        indexedDB.deleteDatabase("hazcuentas-database");

        // Deletind the indexedDB for hascuenta-pictures-database
        indexedDB.deleteDatabase("hazcuentas-pictures-database");

        // Deletind the indexedDB for hascuenta-pr-pictures-database
        indexedDB.deleteDatabase("hazcuentas-pr-pictures-database");
    }

    useEffect(() => {
        const unsuscribe = firebase.auth.onAuthStateChanged(currentUser => {
            if (currentUser) {
                setUser(currentUser);
                setAuth(true);
                setEmailVerified(currentUser.emailVerified);
                localStorage.setItem("accessToken", JSON.stringify(currentUser.accessToken ? true : false));
                localStorage.setItem("isEmailVerified", JSON.stringify(currentUser.emailVerified));
            } else {
                deleteAllInformation();
            }
        });
        return () => unsuscribe();
    });

    async function downloadUserPictures(pictureLocation) {
        try {
            if (!pictureLocation) {
                setUserPictures(null);
                deleteKey("userPictures");
            } else {
                if (userPictures ? (userPictures.path !== pictureLocation) : false) {
                    // Si es diferente a lo que tengo guardado
                    // This is a recursive function that download all the dimensions
                    // of a picture store in firebase store
                    let requestCounter = 6;

                    const getPictureByDimensions = async (dimensions) => {
                        try {
                            const locationPath = `${pictureLocation}${dimensions}`;
                            const imageRef = ref(firebase.storage, locationPath);
                            const url = await getDownloadURL(imageRef);
                            const xhr = new XMLHttpRequest();

                            xhr.responseType = 'blob';
                            xhr.onload = () => {
                                const blob = xhr.response;
                                const fr = new FileReader();
                                fr.readAsDataURL(blob);
                                fr.addEventListener('load', () => {
                                    const urlData = fr.result;
                                    if (dimensions === "_140x140") {
                                        dbKey.d140x140 = urlData;
                                        getPictureByDimensions("_300x300");
                                    } else {
                                        if (dimensions === "_300x300") {
                                            dbKey.d300x300 = urlData;
                                            getPictureByDimensions("_600x600");
                                        } else {
                                            if (dimensions === "_600x600") {
                                                dbKey.d600x600 = urlData;
                                                setUserPictures(dbKey);
                                                saveKeyInIndexedDB("userPictures", dbKey);
                                            }
                                        }
                                    }
                                });
                            };
                            xhr.open('GET', url);
                            xhr.send();
                        } catch (error) {
                            if ((requestCounter > 0) && (error.code !== "storage/unauthorized")) {
                                requestCounter--;
                                setTimeout(() => {
                                    getPictureByDimensions("_140x140");
                                }, 1000);
                            } else {
                                console.error(error);
                            }
                        }
                    }

                    const dbKey = {
                        "path": pictureLocation,
                        "d140x140": null,
                        "d300x300": null,
                        "d600x600": null,
                    }

                    getPictureByDimensions("_140x140");
                } else {
                    // Si es diferente a lo que tengo guardado
                    // This is a recursive function that download all the dimensions
                    // of a picture store in firebase store
                    let requestCounter = 6;

                    const getPictureByDimensions = async (dimensions) => {
                        try {
                            const locationPath = `${pictureLocation}${dimensions}`;
                            const imageRef = ref(firebase.storage, locationPath);
                            const url = await getDownloadURL(imageRef);
                            const xhr = new XMLHttpRequest();

                            xhr.responseType = 'blob';
                            xhr.onload = () => {
                                const blob = xhr.response;
                                const fr = new FileReader();
                                fr.readAsDataURL(blob);
                                fr.addEventListener('load', () => {
                                    const urlData = fr.result;
                                    if (dimensions === "_140x140") {
                                        dbKey.d140x140 = urlData;
                                        getPictureByDimensions("_300x300");
                                    } else {
                                        if (dimensions === "_300x300") {
                                            dbKey.d300x300 = urlData;
                                            getPictureByDimensions("_600x600");
                                        } else {
                                            if (dimensions === "_600x600") {
                                                dbKey.d600x600 = urlData;
                                                setUserPictures(dbKey);
                                                saveKeyInIndexedDB("userPictures", dbKey);
                                            }
                                        }
                                    }
                                });
                            };
                            xhr.open('GET', url);
                            xhr.send();
                        } catch (error) {
                            if ((requestCounter > 0) && (error.code !== "storage/unauthorized")) {
                                requestCounter--;
                                setTimeout(() => {
                                    getPictureByDimensions("_140x140");
                                }, 1000);
                            } else {
                                console.error(error);
                            }
                        }
                    }

                    const dbKey = {
                        "path": pictureLocation,
                        "d140x140": null,
                        "d300x300": null,
                        "d600x600": null,
                    }

                    getPictureByDimensions("_140x140");
                }
            };
        } catch (error) {
            console.error(error);
        }
    }

    async function downloadAccountPictures(pictureLocation) {
        try {
            if (!pictureLocation) {
                setAccountPictures(null);
                deleteKey("accountPictures");
            } else {
                if (accountPictures ? (accountPictures.path !== pictureLocation) : false) {
                    // Si es diferente a lo que tengo guardado
                    // This is a recursive function that download all the dimensions
                    // of a picture store in firebase store
                    let requestCounter = 6;

                    const getPictureByDimensions = async (dimensions) => {
                        try {
                            const locationPath = `${pictureLocation}${dimensions}`;
                            const imageRef = ref(firebase.storage, locationPath);
                            const url = await getDownloadURL(imageRef);
                            const xhr = new XMLHttpRequest();

                            xhr.responseType = 'blob';
                            xhr.onload = () => {
                                const blob = xhr.response;
                                const fr = new FileReader();
                                fr.readAsDataURL(blob);
                                fr.addEventListener('load', () => {
                                    const urlData = fr.result;
                                    if (dimensions === "_140x140") {
                                        dbKey.d140x140 = urlData;
                                        getPictureByDimensions("_300x300");
                                    } else {
                                        if (dimensions === "_300x300") {
                                            dbKey.d300x300 = urlData;
                                            getPictureByDimensions("_600x600");
                                        } else {
                                            if (dimensions === "_600x600") {
                                                dbKey.d600x600 = urlData;
                                                setAccountPictures(dbKey);
                                                saveKeyInIndexedDB("accountPictures", dbKey);
                                            }
                                        }
                                    }
                                });
                            };
                            xhr.open('GET', url);
                            xhr.send();
                        } catch (error) {
                            if ((requestCounter > 0) && (error.code !== "storage/unauthorized")) {
                                requestCounter--;
                                setTimeout(() => {
                                    getPictureByDimensions("_140x140");
                                }, 1000);
                            } else {
                                console.error(error);
                            }
                        }
                    }

                    const dbKey = {
                        "path": pictureLocation,
                        "d140x140": null,
                        "d300x300": null,
                        "d600x600": null,
                    }

                    getPictureByDimensions("_140x140");
                } else {
                    // Si es diferente a lo que tengo guardado
                    // This is a recursive function that download all the dimensions
                    // of a picture store in firebase store
                    let requestCounter = 6;

                    const getPictureByDimensions = async (dimensions) => {
                        try {
                            const locationPath = `${pictureLocation}${dimensions}`;
                            const imageRef = ref(firebase.storage, locationPath);
                            const url = await getDownloadURL(imageRef);
                            const xhr = new XMLHttpRequest();

                            xhr.responseType = 'blob';
                            xhr.onload = () => {
                                const blob = xhr.response;
                                const fr = new FileReader();
                                fr.readAsDataURL(blob);
                                fr.addEventListener('load', () => {
                                    const urlData = fr.result;
                                    if (dimensions === "_140x140") {
                                        dbKey.d140x140 = urlData;
                                        getPictureByDimensions("_300x300");
                                    } else {
                                        if (dimensions === "_300x300") {
                                            dbKey.d300x300 = urlData;
                                            getPictureByDimensions("_600x600");
                                        } else {
                                            if (dimensions === "_600x600") {
                                                dbKey.d600x600 = urlData;
                                                setAccountPictures(dbKey);
                                                saveKeyInIndexedDB("accountPictures", dbKey);
                                            }
                                        }
                                    }
                                });
                            };
                            xhr.open('GET', url);
                            xhr.send();
                        } catch (error) {
                            if ((requestCounter > 0) && (error.code !== "storage/unauthorized")) {
                                requestCounter--;
                                setTimeout(() => {
                                    getPictureByDimensions("_140x140");
                                }, 1000);
                            } else {
                                console.error(error);
                            }
                        }
                    }

                    const dbKey = {
                        "path": pictureLocation,
                        "d140x140": null,
                        "d300x300": null,
                        "d600x600": null,
                    }

                    getPictureByDimensions("_140x140");
                }
            };
        } catch (error) {
            console.error(error);
        }
    }

    const deleteKey = (keyId) => {
        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-store")) {
                db.createObjectStore("hazcuentas-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-store', 'readwrite');

            transaction.onerror = (err) => { console.warn(err); }

            const store = transaction.objectStore('hazcuentas-store');
            store.delete(keyId);
        }
    }

    const deleteKeyFromCollection = (keyId, collection) => {
        const indexedDB = window.indexedDB;
        const request = indexedDB.open(`hazcuentas-${collection}-pictures-database`, 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains(`hazcuentas-${collection}-pictures-store`)) {
                db.createObjectStore(`hazcuentas-${collection}-pictures-store`, { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction(`hazcuentas-${collection}-pictures-store`, 'readwrite');

            transaction.onerror = (err) => { console.warn(err); }

            const store = transaction.objectStore(`hazcuentas-${collection}-pictures-store`);
            store.delete(keyId);
        }
    }

    const saveKeyInIndexedDB = (dbId, dbKey) => {
        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-store")) {
                db.createObjectStore("hazcuentas-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-store', 'readwrite');

            transaction.onerror = (err) => { console.warn(err); }

            const store = transaction.objectStore('hazcuentas-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;

                if (request.result) {
                    store.put({
                        id: dbId,
                        key: dbKey
                    });
                } else {
                    store.add({
                        id: dbId,
                        key: dbKey
                    });
                }
            }

            requestToCheck.onerror = () => {
                console.log("Error getting hazcuentas-store");
            }
        }
    }

    // This function save one picture in the indexeddb
    // Limitof 150 pictures
    const setPictureInMemory = (dbKey, productId) => {
        const dbId = productId;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pictures-store")) {
                db.createObjectStore("hazcuentas-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pictures-store', 'readwrite');

            transaction.onerror = (err) => { console.warn(err); }

            const store = transaction.objectStore('hazcuentas-pictures-store');

            // Using getAllKeys() method to retrieve all keys
            const getAllKeysRequest = store.getAllKeys();

            getAllKeysRequest.onsuccess = function (event) {
                // Array of keys retrieved successfully
                const keysCount = event.target.result;

                let numberOfKeysToDelete = Math.floor(keysCount.length - 150);
                numberOfKeysToDelete = numberOfKeysToDelete < 0 ? 0 : numberOfKeysToDelete;

                const requestToCheck = store.get(dbId);

                requestToCheck.onsuccess = (ev) => {
                    const request = ev.target;

                    if (request.result) {
                        const requestToUpdate = store.put({
                            id: dbId,
                            key: dbKey
                        });
                        requestToUpdate.onsuccess = () => {/*console.log("IndexedDB put success: " + dbId);*/ }
                        requestToUpdate.onerror = () => {/*console.log("IndexedDB put error: " + dbId);*/ }
                    } else {
                        const requestToAdd = store.add({
                            id: dbId,
                            key: dbKey
                        });

                        requestToAdd.onsuccess = async () => {
                            if (numberOfKeysToDelete > 0) {
                                // Sort the keys to delete the first 3
                                const keysToDelete = keysCount.slice(0, numberOfKeysToDelete);

                                // Delete each key
                                for (const key of keysToDelete) {
                                    await deleteItem(store, key);
                                }
                            }
                        }
                        requestToAdd.onerror = () => {/*console.log("IndexedDB add error: " + dbId);*/ }
                    }
                }

                function deleteItem(objectStore, key) {
                    return new Promise((resolve, reject) => {
                        const deleteRequest = objectStore.delete(key);

                        deleteRequest.onsuccess = function () {
                            resolve();
                        };

                        deleteRequest.onerror = function () {
                            reject(deleteRequest.error);
                        };
                    });
                }

                requestToCheck.onerror = () => {
                    console.log("Error getting hazcuentas-store");
                }
            };

            getAllKeysRequest.onerror = function (event) {
                // Error occurred while retrieving keys
                console.error("Error retrieving keys:", event.target.error);
            };
        }
    }

    // This function save the first 30 picture for products
    // Only for preformace
    // Save in hazcuentas-pr-pictures-database
    const setPicturesInMemory = (
        dbKey,
        productId
    ) => {

        const dbId = productId;
        const indexedDB = window.indexedDB;
        const request = indexedDB.open(`hazcuentas-pr-pictures-database`, 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains(`hazcuentas-pr-pictures-store`)) {
                db.createObjectStore(`hazcuentas-pr-pictures-store`, { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction(`hazcuentas-pr-pictures-store`, 'readwrite');

            transaction.onerror = (err) => { console.warn(err); }

            const store = transaction.objectStore(`hazcuentas-pr-pictures-store`);
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;

                if (request.result) {
                    const requestToUpdate = store.put({
                        id: dbId,
                        key: dbKey
                    });
                    requestToUpdate.onsuccess = () => {/*console.log("IndexedDB put success: " + dbId);*/ }
                    requestToUpdate.onerror = () => {/*console.log("IndexedDB put error: " + dbId);*/ }
                } else {
                    const requestToAdd = store.add({
                        id: dbId,
                        key: dbKey
                    });
                    requestToAdd.onsuccess = () => {/*console.log("IndexedDB add success: " + dbId);*/ }
                    requestToAdd.onerror = () => {/*console.log("IndexedDB add error: " + dbId);*/ }
                }
            }

            requestToCheck.onerror = () => {
                console.log("Error getting hazcuentas-store");
            }
        }
    }

    // When the user changes accounts
    const partiallyEliminateCustomers = () => {
        deleteKey("shoppingCart");
        setShoppingCart(null);

        deleteKey("shift");
        setShift(null);

        deleteKey("authorizedUsers");
        setAuthorizedUsers(null);

        deleteKey("invoiceTemplate");
        setInvoiceTemplate(null);

        deleteKey("adminData");
        setAdminData(null);

        setAccountPictures(null);
        deleteKey("accountPictures");

        deleteKey("customers");
        setCustomers(null);

        deleteKey("customersStatistics");
        setCustomersStatistics(null);

        deleteKey("suppliers");
        setSuppliers(null);

        deleteKey("suppliersStatistics");
        setSuppliersStatistics(null);

        localStorage.removeItem("itemsDeleted");
        localStorage.removeItem("isProductsView");
        localStorage.removeItem("isLabelSettings");

        deleteKey("products");
        setProducts(null);

        deleteKey("productsStatistics");
        setProductsStatistics(null);

        deleteKey("sales");
        setSales(null);

        deleteKey("salesStatistics")
        setSalesStatistics(null);

        deleteKey("purchases");
        setPurchases(null);

        deleteKey("purchasesStatistics")
        setPurchasesStatistics(null);
    }

    // When the account is close but there no account selected yet
    const eliminateAllCustomers = () => {
        deleteKey("shoppingCart");
        setShoppingCart(null);

        deleteKey("shift");
        setShift(null);

        deleteKey("authorizedUsers");
        setAuthorizedUsers(null);

        deleteKey("invoiceTemplate");
        setInvoiceTemplate(null);

        deleteKey("accessTo");
        setAccessTo(null);

        deleteKey("adminData");
        setAdminData(null);

        setAccountPictures(null);
        deleteKey("accountPictures");

        deleteKey("accountData");
        setAccountData(null);

        deleteKey("customers");
        setCustomers(null);

        deleteKey("customersStatistics");
        setCustomersStatistics(null);

        deleteKey("suppliers");
        setSuppliers(null);

        deleteKey("suppliersStatistics");
        setSuppliersStatistics(null);

        setAdminAccess(false);
        localStorage.removeItem("isAdminAccess");

        localStorage.removeItem("isAccountSelected");
        setAccountSelected(null);

        localStorage.removeItem("itemsDeleted");
        localStorage.removeItem("isProductsView");
        localStorage.removeItem("isLabelSettings");

        deleteKey("products");
        setProducts(null);

        deleteKey("productsStatistics");
        setProductsStatistics(null);

        deleteKey("sales");
        setSales(null);

        deleteKey("salesStatistics")
        setSalesStatistics(null);

        deleteKey("purchases");
        setPurchases(null);

        deleteKey("purchasesStatistics")
        setPurchasesStatistics(null);
    }

    const getItemPrice = (item) => {
        if (item.priceLetter === "A") {
            return item.product.priceWithoutTax;
        } else {
            if (item.product.priceList[`price${item.priceLetter}`] !== null) {
                return item.product.priceList[`price${item.priceLetter}`];
            } else {
                return item.product.priceWithoutTax;
            }
        }
    }

    const getPriceLetterIfExist = (priceList, letter) => {
        if (priceList[`price${letter}`] !== null) {
            return letter;
        } else {
            return "A";
        }
    }

    const calculateShoppingCart = (cart) => {
        const items = [];

        let subtotal = 0;
        let desc = 0;

        const itemsDiscounts = [];

        for (let i = 0; i < cart.items.length; i++) {
            const item = cart.items[i];
            subtotal += (getItemPrice(item) * item.quantity);
        }

        if (subtotal !== 0 && cart.discount && cart.discount.type === "amount") {
            for (let i = 0; i < cart.items.length; i++) {
                const item = cart.items[i];
                const discountPercentage = (getItemPrice(item) / subtotal);
                itemsDiscounts[i] = (cart.discount.value * discountPercentage) * item.quantity;
            }
        }

        if (subtotal !== 0 && cart.discount && cart.discount.type === "percentage") {
            for (let i = 0; i < cart.items.length; i++) {
                const item = cart.items[i];
                const discountPercentage = cart.discount.value / 100;
                itemsDiscounts[i] = (getItemPrice(item) * discountPercentage) * item.quantity;
            }
        }

        let itbis = 0;
        let total = 0;

        // Then I apply the discounts to each item separately 
        // to then calculate the ITBIS for each item separately
        // Likewise, calculate the total ITBIS and the TOTAL of the invoice in each iteration.

        for (let i = 0; i < cart.items.length; i++) {
            const item = cart.items[i];

            let newItbis = 0;
            let newValue = 0;

            const itemSubtotal = (getItemPrice(item) * item.quantity) - (itemsDiscounts[i] ? itemsDiscounts[i] : 0);
           
            if (item.product.itbisPercentage === "18%") {
                newItbis = itemSubtotal * 0.18;
            } else {
                if (item.product.itbisPercentage === "16%") {
                    newItbis = itemSubtotal * 0.16;
                }
            }

            const unitValue = getItemPrice(item) + (newItbis / item.quantity) - ((itemsDiscounts[i] ? itemsDiscounts[i] : 0) / item.quantity);

            newValue = itemSubtotal + newItbis;
         
            const newItem = {
                ...item,
                "unitValue": unitValue,
                "desc": itemsDiscounts[i] ? itemsDiscounts[i] : 0,
                "itbis": newItbis,
                "value": newValue,
            }

            items.push(newItem);

            if (cart.discount) {
                if (cart.discount.type === "amount") {
                    desc = cart.discount.value;
                } else {
                    desc += newItem.desc;
                }
            } else {
                desc = 0;
            }

            itbis += newItem.itbis;
            total += newItem.value;
        };

        let newShoppingCart = {
            ...cart,
            "items": items,
            "subtotal": subtotal,
            "desc": desc,
            "itbis": itbis,
            "total":  total,
        }
        
        return newShoppingCart
    }

    const getItemDataForShoppingCart = (product, quantity, priceLetter) => {
        return {
            "quantity": quantity,
            "priceLetter": priceLetter,
            "desc": null,
            "itbis": null,
            "value": null,
            "product": product
        };
    }

    const getAmountDataForShoppingCart = (items) => {
        let tempSubtotal = 0;
        let tempDesc = 0;
        let tempItbis = 0;
        let tempTotal = 0;

        items.forEach(item => {
            tempSubtotal += (getItemPrice(item) * item.quantity);
            tempDesc += item.desc;
            tempItbis += item.itbis;
            tempTotal += item.value;
        });

        const subtotal = Number.parseFloat(tempSubtotal).toFixed(2);
        const desc = Number.parseFloat(tempDesc).toFixed(2);
        const itbis = Number.parseFloat(tempItbis).toFixed(2);
        const total = Number.parseFloat(tempTotal).toFixed(2);

        return {
            "subtotal": Number(subtotal),
            "desc": Number(desc),
            "itbis": Number(itbis),
            "total": Number(total),
        }
    }

    const addToShoppingCart = (product) => {
        if (shoppingCart) {
            const items = shoppingCart.items;
            const isIncluded = items.some(item => item.product.id === product.id);

            if (!isIncluded) {
                const item = getItemDataForShoppingCart(product, 1, "A");

                items.unshift(item);

                const amounts = getAmountDataForShoppingCart(items);

                let newShoppingCart = {
                    ...shoppingCart,
                    "payments": null,
                    "discount": null,
                    "items": items,
                    "subtotal": amounts.subtotal,
                    "desc": amounts.desc,
                    "itbis": amounts.itbis,
                    "total": amounts.total,
                }

                newShoppingCart = calculateShoppingCart(newShoppingCart);
                setShoppingCart(newShoppingCart);
                saveKeyInIndexedDB("shoppingCart", newShoppingCart);

                return true;
            } else {
                const newItems = [];

                for (let i = 0; i < items.length; i++) {
                    if (items[i].product.id === product.id) {
                        newItems.push(getItemDataForShoppingCart(product, 1, "A"));
                    } else {
                        newItems.push(items[i]);
                    }
                }

                const amounts = getAmountDataForShoppingCart(newItems);

                let newShoppingCart = {
                    ...shoppingCart,
                    "payments": null,
                    "discount": null,
                    "items": newItems,
                    "subtotal": amounts.subtotal,
                    "desc": amounts.desc,
                    "itbis": amounts.itbis,
                    "total": amounts.total,
                }

                newShoppingCart = calculateShoppingCart(newShoppingCart);
                setShoppingCart(newShoppingCart);
                saveKeyInIndexedDB("shoppingCart", newShoppingCart);

                return true;
            }
        } else {
            const item = getItemDataForShoppingCart(product, 1, "A");
            const newItems = [item];
            const amounts = getAmountDataForShoppingCart(newItems);

            let newShoppingCart = {
                "salesId": null,
                "customer": null,
                "payments": null,
                "discount": null,
                "items": newItems,
                "subtotal": amounts.subtotal,
                "desc": amounts.desc,
                "itbis": amounts.itbis,
                "total": amounts.total,
            }

            newShoppingCart = calculateShoppingCart(newShoppingCart);
            setShoppingCart(newShoppingCart);
            saveKeyInIndexedDB("shoppingCart", newShoppingCart);

            return true;
        }
    }

    const updateProductInShoppingCart = (product) => {
        if (shoppingCart) {
            const items = shoppingCart.items;

            const isIncluded = items.some(item => item.product.id === product.id);

            if (isIncluded) {
                const newItems = [];

                for (let i = 0; i < items.length; i++) {
                    if (items[i].product.id === product.id) {
                        const newPriceLetter = getPriceLetterIfExist(product.priceList, items[i].priceLetter);
                        const item = getItemDataForShoppingCart(product, items[i].quantity, newPriceLetter);
                        newItems.push(item);
                    } else {
                        newItems.push(items[i]);
                    }
                }

                const amounts = getAmountDataForShoppingCart(newItems);

                let newShoppingCart = {
                    ...shoppingCart,
                    "discount": Number(amounts.total) === shoppingCart.total ? shoppingCart.discount : null,
                    "items": newItems,
                    "subtotal": amounts.subtotal,
                    "desc": amounts.desc,
                    "itbis": amounts.itbis,
                    "total": amounts.total,
                }

                newShoppingCart = calculateShoppingCart(newShoppingCart);
                setShoppingCart(newShoppingCart);
                saveKeyInIndexedDB("shoppingCart", newShoppingCart);
            }
        }
    }

    const getProductUpdatedInShoppingCart = (id, products) => {
        return products.find((product) => product.id === id);
    }

    const updateProductsInShoppingCart = (products) => {
        if (shoppingCart) {
            const items = shoppingCart.items;
            const newItems = [];

            for (let i = 0; i < items.length; i++) {
                const productUpdated = getProductUpdatedInShoppingCart(items[i].product.id, products);
                if (productUpdated) {
                    const newPriceLetter = getPriceLetterIfExist(productUpdated.priceList, items[i].priceLetter);
                    const item = getItemDataForShoppingCart(productUpdated, items[i].quantity, newPriceLetter);
                    newItems.push(item);
                } else {
                    newItems.push(items[i]);
                }
            }

            const amounts = getAmountDataForShoppingCart(newItems);

            let newShoppingCart = {
                ...shoppingCart,
                "items": newItems,
                "subtotal": amounts.subtotal,
                "desc": amounts.desc,
                "itbis": amounts.itbis,
                "total": amounts.total,
            }

            newShoppingCart = calculateShoppingCart(newShoppingCart);
            setShoppingCart(newShoppingCart);
            saveKeyInIndexedDB("shoppingCart", newShoppingCart);
        }
    }

    const updateCustomerInShoppingCart = (customer) => {
        if (shoppingCart) {
            if (shoppingCart.customer) {
                if (shoppingCart.customer.id === customer.id) {
                    setShoppingCart({
                        ...shoppingCart,
                        "customer": customer,
                    });
                    saveKeyInIndexedDB("shoppingCart", {
                        ...shoppingCart,
                        "customer": customer,
                    });
                }
            }
        }
    }

    const removeFromShoppingCart = (id) => {
        if (shoppingCart) {
            const items = shoppingCart.items;
            const newItems = []

            for (let i = 0; i < items.length; i++) {
                if (items[i].product.id === id) {
                    // Do not keep it !!!!
                } else {
                    newItems.push(items[i]);
                }
            }

            const amounts = getAmountDataForShoppingCart(newItems);

            let newShoppingCart = {
                ...shoppingCart,
                "discount": null,
                "items": newItems,
                "subtotal": amounts.subtotal,
                "desc": amounts.desc,
                "itbis": amounts.itbis,
                "total": amounts.total,
            }

            newShoppingCart = calculateShoppingCart(newShoppingCart);
            setShoppingCart(newShoppingCart);
            saveKeyInIndexedDB("shoppingCart", newShoppingCart);

            return newItems.length;
        } else {
            return null;
        }
    }

    const removeMultipleItemsFromShoppingCart = (ids) => {
        if (shoppingCart) {
            const items = shoppingCart.items;
            const newItems = []

            for (let i = 0; i < items.length; i++) {
                if (ids.includes(items[i].product.id)) {
                    // Do not keep it !!!!
                } else {
                    newItems.push(items[i]);
                }
            }

            const amounts = getAmountDataForShoppingCart(newItems);

            let newShoppingCart = {
                ...shoppingCart,
                "discount": null,
                "items": newItems,
                "subtotal": amounts.subtotal,
                "desc": amounts.desc,
                "itbis": amounts.itbis,
                "total": amounts.total,
            }

            newShoppingCart = calculateShoppingCart(newShoppingCart);
            setShoppingCart(newShoppingCart);
            saveKeyInIndexedDB("shoppingCart", newShoppingCart);

            return newItems.length;
        } else {
            return null;
        }
    }

    const changePriceInShoppigCart = (id, letter) => {
        if (shoppingCart) {
            const items = shoppingCart.items;
            const newItems = []

            for (let i = 0; i < items.length; i++) {
                if (items[i].product.id === id) {
                    const newPriceLetter = getPriceLetterIfExist(items[i].product, letter);
                    const item = getItemDataForShoppingCart(items[i].product, items[i].quantity, newPriceLetter);
                    newItems.push(item);
                } else {
                    newItems.push(items[i]);
                }
            }

            const amounts = getAmountDataForShoppingCart(newItems);

            let newShoppingCart = {
                ...shoppingCart,
                "discount": null,
                "items": newItems,
                "subtotal": amounts.subtotal,
                "desc": amounts.desc,
                "itbis": amounts.itbis,
                "total": amounts.total,
            }

            newShoppingCart = calculateShoppingCart(newShoppingCart);
            setShoppingCart(newShoppingCart);
            saveKeyInIndexedDB("shoppingCart", newShoppingCart);
        }
    }

    const changeItemQuantityShoppingCart = (id, quantity) => {
        if (shoppingCart) {
            const items = shoppingCart.items;
            const newItems = []

            for (let i = 0; i < items.length; i++) {
                if (items[i].product.id === id) {
                    const item = getItemDataForShoppingCart(items[i].product, quantity, items[i].priceLetter);
                    newItems.push(item);
                } else {
                    newItems.push(items[i]);
                }
            }

            const amounts = getAmountDataForShoppingCart(newItems);

            let newShoppingCart = {
                ...shoppingCart,
                "discount": null,
                "items": newItems,
                "subtotal": amounts.subtotal,
                "desc": amounts.desc,
                "itbis": amounts.itbis,
                "total": amounts.total,
            }

            newShoppingCart = calculateShoppingCart(newShoppingCart);
            setShoppingCart(newShoppingCart);
            saveKeyInIndexedDB("shoppingCart", newShoppingCart);
        }
    }

    const isItemInShoppingCart = (productId) => {
        if (shoppingCart) {
            if (shoppingCart.items.length > 0) {
                return shoppingCart.items.some(item => item.product.id === productId);
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const deleteShoppingCart = () => {
        setShoppingCart(false);
        saveKeyInIndexedDB("shoppingCart", false);
    }

    const setCustomerInShoppingCart = (customer) => {
        if (shoppingCart) {
            setShoppingCart({
                ...shoppingCart,
                "customer": customer,
            });

            saveKeyInIndexedDB("shoppingCart", {
                ...shoppingCart,
                "customer": customer,
            });
        } else {
            const newShoppingCart = {
                "salesId": null,
                "customer": customer,
                "discount": null,
                "items": [],
                "subtotal": 0,
                "desc": 0,
                "itbis": 0,
                "total": 0,
            }

            setShoppingCart(newShoppingCart);
            saveKeyInIndexedDB("shoppingCart", newShoppingCart);
        }
    }

    const removeCustomerFromShoppingCart = () => {
        if (shoppingCart) {
            if (shoppingCart.customer) {
                const newShoppingCart = {
                    ...shoppingCart,
                    "customer": null,
                };
                setShoppingCart(newShoppingCart)
                saveKeyInIndexedDB("shoppingCart", newShoppingCart);
            }
        }
    }

    const removeCustomerIfIncludedFromShoppingCart = (ids) => {
        if (shoppingCart) {
            if (shoppingCart.customer) {
                if (ids.includes(shoppingCart.customer.id)) {
                    const newShoppingCart = {
                        ...shoppingCart,
                        "customer": null,
                    };
                    setShoppingCart(newShoppingCart)
                    saveKeyInIndexedDB("shoppingCart", newShoppingCart);
                }
            }
        }
    }

    const [isCartUpdated, setIsCartUpdated] = useState(false);

    const updateShoppingCartInfoFromServer = async () => {
        if (shoppingCart && (!shoppingCart.salesId)) {
            if (!isCartUpdated) {
                const updatedItems = shoppingCart.items;
                let updatedCustomer = shoppingCart.customer;

                if (shoppingCart.customer) {
                    try {
                        const customerRef = doc(firebase.db, `/accounts/${accountData.id}/customers`, shoppingCart.customer.id);
                        const customerSnap = await getDocFromServer(customerRef);
                        if (customerSnap.exists()) {
                            updatedCustomer = {
                                ...customerSnap.data(),
                                id: customerSnap.id,
                            }
                        } else {
                            updatedCustomer = null;
                        }
                    } catch (error) {
                        console.error(error);
                    }
                }

                if (shoppingCart.items.length > 0) {
                    try {
                        for (let i = 0; i < shoppingCart.items.length; i++) {
                            const productRef = doc(firebase.db, `/accounts/${accountData.id}/products`, shoppingCart.items[i].product.id);
                            const productSnap = await getDocFromServer(productRef);

                            if (productSnap.exists()) {
                                const productData = {
                                    ...productSnap.data(),
                                    id: productSnap.id,
                                }

                                delete productData.searchKeys;

                                const newPriceLetter = getPriceLetterIfExist(productData.priceList, shoppingCart.items[i].priceLetter);
                                const item = getItemDataForShoppingCart(productData, shoppingCart.items[i].quantity, newPriceLetter);

                                updatedItems[i] = item;
                            } else {
                                // Product have been deleted
                                updatedItems[i] = null;
                            }
                        }
                    } catch (error) {
                        console.error(error);
                    }
                }

                const tempNewItems = [];

                // if item === null remove this item from shopping cart
                updatedItems.forEach(item => {
                    if (item) {
                        tempNewItems.push(item);
                    }
                });

                const amounts = getAmountDataForShoppingCart(tempNewItems);

                let newShoppingCart = {
                    ...shoppingCart,
                    "customer": updatedCustomer,
                    "items": tempNewItems,
                    "subtotal": amounts.subtotal,
                    "desc": amounts.desc,
                    "itbis": amounts.itbis,
                    "total": amounts.total,
                }
    
                newShoppingCart = calculateShoppingCart(newShoppingCart);
                setShoppingCart(newShoppingCart);
                saveKeyInIndexedDB("shoppingCart", newShoppingCart);
                setIsCartUpdated(true);
            }
        }
    }

    const applyDiscountToShoppingCart = (data) => {
        if (shoppingCart) {
            let newShoppingCart = {
                ...shoppingCart,
                "discount": data,
            };

            newShoppingCart = calculateShoppingCart(newShoppingCart);
            setShoppingCart(newShoppingCart);
            saveKeyInIndexedDB("shoppingCart", newShoppingCart);
        }
    }

    const removeDiscountFromShoppingCart = () => {
        if (shoppingCart) {
            let newShoppingCart = {
                ...shoppingCart,
                "discount": null,
            };

            newShoppingCart = calculateShoppingCart(newShoppingCart);
            setShoppingCart(newShoppingCart);
            saveKeyInIndexedDB("shoppingCart", newShoppingCart);
        }
    }

    useEffect(() => {
        if (user) {
            const unsub = onSnapshot(doc(firebase.db, "users", user.uid), (doc) => {
                const userDataUpdated = {
                    ...doc.data(),
                    "id": doc.id,
                };

                if (doc.data()) {
                    setUserData(userDataUpdated);
                    downloadUserPictures(userDataUpdated.pictureLocation);
                    saveKeyInIndexedDB("userData", userDataUpdated);
                }
            });
            return () => unsub();
        }
        // eslint-disable-next-line
    }, [user]);

    async function verifiedIfNotificationsCollectionIsReallyEmpty(q) {
        try {
            const snapshot = await getCountFromServer(q);
            if (!(snapshot.data().count > 0)) {
                setNotifications([]);
                saveKeyInIndexedDB("notifications", []);
            }
        } catch (error) {
            console.warn(error);
        }
    }

    useEffect(() => {
        if (user) {
            const q = query(
                collection(firebase.db, `users/${user.uid}/notifications`),
                orderBy("time", "desc"),
                limit(10)
            );

            const unsub = onSnapshot(q, (querySnapshot) => {
                const res = [];

                querySnapshot.forEach((doc) => {
                    if (doc.data()) {
                        const thisDoc = {
                            ...doc.data(),
                            "id": doc.id,
                        }
                        res.push(thisDoc);
                    }
                });

                if (res.length > 0) {
                    setNotifications(res);
                    saveKeyInIndexedDB("notifications", res);
                } else {
                    verifiedIfNotificationsCollectionIsReallyEmpty(q);
                }

            }, err => {
                console.error(err);
            });

            return () => unsub();
        }
        // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        if (accountData) {
            const unsubAccountData = onSnapshot(doc(firebase.db, "accounts", accountData.id), (doc) => {
                const accounDataUpdated = {
                    ...doc.data(),
                    "id": doc.id,
                };

                if (doc.data()) {
                    setAccountData(accounDataUpdated);
                    saveKeyInIndexedDB("accountData", accounDataUpdated);
                    setInvoiceTemplateListener(true);
                }
            }, err => {
                console.error(err);
                if (err.code === "permission-denied") {
                    eliminateAllCustomers();
                };
            });
            return () => unsubAccountData();
        }
        // eslint-disable-next-line
    }, [accountSelected]);

    const [customerListener, setCustomerListener] = useState(false);
    const [supplierListener, setSupplierListener] = useState(false);
    const [productListener, setProductListener] = useState(false);

    const [saleListener, setSaleListener] = useState(false);
    const [purchasesListener, setPurchasesListener] = useState(false);

    const [authorizedUsersListener, setAuthorizedUsersListener] = useState(false);

    const [invoiceTemplateListener, setInvoiceTemplateListener] = useState(false);

    async function verifiedIfAuthorizedUsersCollectionIsReallyEmpty(q) {
        try {
            const snapshot = await getCountFromServer(q);

            if (!(snapshot.data().count > 0)) {
                setAuthorizedUsers([]);
                saveKeyInIndexedDB("authorizedUsers", []);
            }

        } catch (error) {
            console.warn(error);
        }
    }

    useEffect(() => {
        if (authorizedUsersListener && accountData && accessTo) {
            if (accessTo.inventory || accessTo.sales) {
                const q = query(collection(firebase.db, `accounts/${accountData.id}/authorizedUsers`));
                const unsub = onSnapshot(q, (querySnapshot) => {
                    const res = [];

                    querySnapshot.forEach((doc) => {
                        if (doc.data()) {
                            res.push({
                                "id": doc.id,
                                "name": doc.data().name,
                                "status": doc.data().status
                            })
                        }
                    });

                    if (res.length > 0) {
                        setAuthorizedUsers(res);
                        saveKeyInIndexedDB("authorizedUsers", res);
                    } else {
                        verifiedIfAuthorizedUsersCollectionIsReallyEmpty(q);
                    }
                });
                return () => unsub();
            }
        }
        // eslint-disable-next-line
    }, [authorizedUsersListener, accessTo]);

    useEffect(() => {
        if (invoiceTemplateListener && accountData) {
            const unsub = onSnapshot(doc(firebase.db, `/accounts/${accountData.id}/templates`, "invoiceTemplate" ), (doc) => {
                const templateUpdated = {
                    ...doc.data(),
                    "id": doc.id,
                };

                if (doc.data()) {
                    setInvoiceTemplate(templateUpdated);
                    saveKeyInIndexedDB("invoiceTemplate", templateUpdated);
                    if (templateUpdated.pictureLocation) {
                        downloadAccountPictures(templateUpdated.pictureLocation);
                    }
                }
            }, err => {
                console.error(err);
                if (err.code === "permission-denied") {
                    deleteKey("invoiceTemplate");
                    setInvoiceTemplate(null);
                };
            });
            return () => unsub();
            
        }
        // eslint-disable-next-line
    }, [invoiceTemplateListener, accountData]);

    useEffect(() => {
        if (accountData) {
            if (managerAccess) {
                const accessToAll = {
                    "metrics": true,
                    "customers": true,
                    "sales": true,
                    "salesSup": true,
                    "salesConsult": true,
                    "products": true,
                    "productsSup": true,
                    "inventory": true,
                    "suppliers": true,
                    "taxes": true,
                    "purchases": true,
                    "purchasesConsult": true,
                }

                setAccessTo(accessToAll);
                saveKeyInIndexedDB("accessTo", accessToAll);
                
            } else {
                const unsubAccessTo = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/authorizedUsers`, userData.id), (doc) => {
                    if (doc.data()) {
                        const accessToUpdated = doc.data().accessTo;

                        setAccessTo(accessToUpdated);
                        saveKeyInIndexedDB("accessTo", accessToUpdated);

                        if (accessToUpdated.sales) {
                            // setShoppingCart(doc.data().shoppingCart);
                            // saveKeyInIndexedDB("shoppingCart", doc.data().shoppingCart);
                            setShift(doc.data().shift);
                            saveKeyInIndexedDB("shift", doc.data().shift);
                        } else {
                            deleteKey("shoppingCart");
                            setShoppingCart(null);
                            deleteKey("shift");
                            setShift(null);
                        }
                    }
                });
                return () => unsubAccessTo();
            }
        }
    // eslint-disable-next-line
    }, [accountSelected, adminAccess, managerAccess]);

    useEffect(() => {
        if (accessTo) {
            if ((!accessTo.salesSup) && shoppingCart && shoppingCart.discount) {
                removeDiscountFromShoppingCart();
            }
        }
    // eslint-disable-next-line
    }, [accessTo]);

    async function verifiedIfCustomersCollectionIsReallyEmpty(q) {
        try {
            const snapshot = await getCountFromServer(q);
            if (!(snapshot.data().count > 0)) {
                setCustomers([]);
                saveKeyInIndexedDB("customers", []);
            }
        } catch (error) {
            console.warn(error);
        }
    }

    useEffect(() => {
        if (accessTo && customerListener && accountData) {
            if (accessTo.customers) {
                const q = query(collection(firebase.db, `accounts/${accountData.id}/customers`), orderBy("lastTransactionTime", "desc"), limit(30));
                const unsub = onSnapshot(q, (querySnapshot) => {
                    const res = [];

                    querySnapshot.forEach((doc) => {
                        if (doc.data()) {
                            const thisDoc = {
                                ...doc.data(),
                                "id": doc.id,
                            }

                            delete thisDoc.searchKeys;

                            res.push(thisDoc)
                        }
                    });

                    if (res.length > 0) {
                        setCustomers(res);
                        saveKeyInIndexedDB("customers", res);
                    } else {
                        verifiedIfCustomersCollectionIsReallyEmpty(q);
                    }

                }, err => {
                    console.error(err);
                    if (err.code === "permission-denied") {
                        deleteKey("customers");
                        setCustomers(null);
                    };
                });
                return () => unsub();
            }

        }
        // eslint-disable-next-line
    }, [customerListener, accessTo]);

    useEffect(() => {
        if (accessTo && customerListener && accountData) {
            if (accessTo.customers) {
                const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/customersStatistics`, "statistics"), (doc) => {
                    const statisticsUpdated = {
                        ...doc.data(),
                        "id": doc.id,
                    };

                    if (doc.data()) {
                        setCustomersStatistics(statisticsUpdated);
                        saveKeyInIndexedDB("customersStatistics", statisticsUpdated);
                    }
                }, err => {
                    console.error(err);
                    if (err.code === "permission-denied") {
                        deleteKey("customersStatistics");
                        setCustomersStatistics(null);
                    };
                });
                return () => unsub();
            }
        }
        // eslint-disable-next-line
    }, [customerListener, accessTo]);

    async function verifiedIfSuppliersCollectionIsReallyEmpty(q) {
        try {
            const snapshot = await getCountFromServer(q);

            if (!(snapshot.data().count > 0)) {
                setSuppliers([]);
                saveKeyInIndexedDB("suppliers", []);
            }

        } catch (error) {
            console.warn(error);
        }
    }

    useEffect(() => {
        if (accessTo && supplierListener && accountData) {
            if (accessTo.suppliers) {
                const q = query(collection(firebase.db, `accounts/${accountData.id}/suppliers`), orderBy("lastTransactionTime", "desc"), limit(30));
                const unsub = onSnapshot(q, (querySnapshot) => {
                    const res = [];

                    querySnapshot.forEach((doc) => {
                        if (doc.data()) {
                            const thisDoc = {
                                ...doc.data(),
                                "id": doc.id,
                            }

                            delete thisDoc.searchKeys;

                            res.push(thisDoc)
                        }
                    });

                    if (res.length > 0) {
                        setSuppliers(res);
                        saveKeyInIndexedDB("suppliers", res);
                    } else {
                        verifiedIfSuppliersCollectionIsReallyEmpty(q);
                    }

                }, err => {
                    console.error(err);
                    if (err.code === "permission-denied") {
                        deleteKey("suppliers");
                        setSuppliers(null);
                    };
                });
                return () => unsub();
            }

        }
        // eslint-disable-next-line
    }, [supplierListener, accessTo]);

    useEffect(() => {
        if (accessTo && supplierListener && accountData) {
            if (accessTo.suppliers) {
                const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/suppliersStatistics`, "statistics"), (doc) => {
                    const statisticsUpdated = {
                        ...doc.data(),
                        "id": doc.id,
                    };

                    if (doc.data()) {
                        setSuppliersStatistics(statisticsUpdated);
                        saveKeyInIndexedDB("suppliersStatistics", statisticsUpdated);
                    }
                }, err => {
                    console.error(err);
                    if (err.code === "permission-denied") {
                        deleteKey("suppliersStatistics");
                        setSuppliersStatistics(null);
                    };
                });
                return () => unsub();
            }
        }
        // eslint-disable-next-line
    }, [supplierListener, accessTo]);

    async function verifiedIfProductCollectionIsReallyEmpty(q) {
        try {
            const snapshot = await getCountFromServer(q);

            if (!(snapshot.data().count > 0)) {
                setProducts([]);
                saveKeyInIndexedDB("products", []);
            }

        } catch (error) {
            console.warn(error);
        }
    }

    useEffect(() => {
        if (accessTo && productListener && accountData) {
            if (accessTo.products) {
                const q = query(collection(firebase.db, `accounts/${accountData.id}/products`), orderBy("nameInsensitive"), limit(30));
                const unsub = onSnapshot(q, (querySnapshot) => {
                    const res = [];

                    querySnapshot.forEach((doc) => {
                        if (doc.data()) {
                            const thisDoc = {
                                ...doc.data(),
                                "id": doc.id,
                            }

                            delete thisDoc.searchKeys;

                            res.push(thisDoc)
                        }
                    });

                    if (res.length > 0) {
                        setProducts(res);
                        saveKeyInIndexedDB("products", res);
                    } else {
                        verifiedIfProductCollectionIsReallyEmpty(q);
                    }

                }, err => {
                    console.error(err);
                    if (err.code === "permission-denied") {
                        deleteKey("products");
                        setProducts(null);
                    };
                });
                return () => unsub();
            }

        }
        // eslint-disable-next-line
    }, [productListener, accessTo]);

    const checkPicturesForProducts = (productsPicturesPaths, keys) => {
        // If an item does exist in productsPicturesPaths (firebase store), but not in keys (device memory)
        // we have to download the pictures for this and then save it in hazcuentas-pr-pictures-database
        const itemsToBeDownload = [];

        productsPicturesPaths.forEach((productsPicturesPath) => {
            if (!(keys.includes(productsPicturesPath.id))) {
                itemsToBeDownload.push(productsPicturesPath);
            }
        });

        // If an item exist in keys (device memory), but not in productsPicturesPaths (firebase store)
        // we have to delete that data from keys (device memory).
        const itemsToBeDeleted = [];

        keys.forEach((key) => {
            if (!(productsPicturesPaths.some(obj => obj.id === key))) {
                itemsToBeDeleted.push(key);
            }
        });

        itemsToBeDownload.forEach((productsPicturesPath) => {
            // This is a recursive function that download all the dimensions
            // of a picture store in firebase store

            const getPictureByDimensions = async (dimensions) => {
                try {
                    const locationPath = `${path}${dimensions}`;
                    const imageRef = ref(firebase.storage, locationPath);

                    const url = await getDownloadURL(imageRef);
                    const xhr = new XMLHttpRequest();

                    xhr.responseType = 'blob';
                    xhr.onload = () => {
                        const blob = xhr.response;
                        const fr = new FileReader();

                        fr.readAsDataURL(blob);
                        fr.addEventListener('load', () => {
                            const urlData = fr.result;

                            if (dimensions === "_140x140") {
                                dbKey.d140x140 = urlData;
                                getPictureByDimensions("_300x300");
                            } else {
                                if (dimensions === "_300x300") {
                                    dbKey.d300x300 = urlData;
                                    getPictureByDimensions("_600x600");
                                } else {
                                    if (dimensions === "_600x600") {
                                        dbKey.d600x600 = urlData;
                                        setPicturesInMemory(dbKey, productsPicturesPath.id)
                                    }
                                }
                            }
                        });
                    };

                    xhr.open('GET', url);
                    xhr.send();

                } catch (error) {
                    console.error(error);
                }
            }

            const path = productsPicturesPath.path;

            const dbKey = {
                "path": path,
                "d140x140": null,
                "d300x300": null,
                "d600x600": null,
            }

            getPictureByDimensions("_140x140");
        });

        itemsToBeDeleted.forEach((key) => {
            deleteKeyFromCollection(key, "pr")
        });
    }

    useEffect(() => {
        if (products) {
            if (products.length > 0) {
                // Here I'm going to update, delete or download
                // the pictures save for this section
                const productsPicturesPaths = [];

                products.forEach(product => {
                    if (product.picturesLocation) {
                        if (product.picturesLocation[0]) {
                            productsPicturesPaths.push({ id: product.id, path: product.picturesLocation[0] })
                        }
                    }
                });

                // Delete the pictures not included in the list
                const indexedDB = window.indexedDB;
                const request = indexedDB.open("hazcuentas-pr-pictures-database", 1);

                request.onerror = function (event) {
                    console.error(event);
                    checkPicturesForProducts(productsPicturesPaths, []);
                }

                request.onupgradeneeded = function () {
                    const db = request.result;
                    if (!db.objectStoreNames.contains("hazcuentas-pr-pictures-store")) {
                        db.createObjectStore("hazcuentas-pr-pictures-store", { keyPath: "id" });
                    }
                }

                request.onerror = () => {
                    console.err("error fetching data");
                    checkPicturesForProducts(productsPicturesPaths, []);
                };

                request.onsuccess = (event) => {
                    // Database opened successfully
                    const db = event.target.result;

                    // Start a transaction to access the object store
                    const transaction = db.transaction(['hazcuentas-pr-pictures-store'], 'readonly');
                    const objectStore = transaction.objectStore('hazcuentas-pr-pictures-store');

                    // Using getAllKeys() method to retrieve all keys
                    const getAllKeysRequest = objectStore.getAllKeys();

                    getAllKeysRequest.onsuccess = function (event) {
                        // Array of keys retrieved successfully
                        const keys = event.target.result;
                        checkPicturesForProducts(productsPicturesPaths, keys);
                    };

                    getAllKeysRequest.onerror = function (event) {
                        // Error occurred while retrieving keys
                        console.error("Error retrieving keys:", event.target.error);
                        checkPicturesForProducts(productsPicturesPaths, []);
                    };
                };
            }
        }
        // eslint-disable-next-line
    }, [products]);

    useEffect(() => {
        if (accessTo && productListener && accountData) {
            if (accessTo.products) {
                const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/productsStatistics`, "statistics"), (doc) => {
                    const statisticsUpdated = {
                        ...doc.data(),
                        "id": doc.id,
                    };

                    if (doc.data()) {
                        setProductsStatistics(statisticsUpdated);
                        saveKeyInIndexedDB("productsStatistics", statisticsUpdated);
                    }
                }, err => {
                    console.error(err);
                    if (err.code === "permission-denied") {
                        deleteKey("productsStatistics");
                        setProductsStatistics(null);
                    };
                });
                return () => unsub();
            }
        }
        // eslint-disable-next-line
    }, [productListener, accessTo]);

    async function verifiedIfSaleCollectionIsReallyEmpty(q) {
        try {
            const snapshot = await getCountFromServer(q);

            if (!(snapshot.data().count > 0)) {
                setSales([]);
                saveKeyInIndexedDB("sales", []);
            }

        } catch (error) {
            console.warn(error);
        }
    }

    useEffect(() => {
        if (accessTo && saleListener && accountData) {
            if (accessTo.sales) {
                const q = query(
                    collection(firebase.db, `accounts/${accountData.id}/sales`),
                    orderBy("time", "desc"),
                    limit(30)
                );

                const unsub = onSnapshot(q, (querySnapshot) => {
                    const res = [];

                    querySnapshot.forEach((doc) => {
                        if (doc.data()) {
                            const thisDoc = {
                                ...doc.data(),
                                "id": doc.id,
                            }

                            delete thisDoc.searchKeys;

                            res.push(thisDoc)
                        }
                    });

                    if (res.length > 0) {
                        setSales(res);
                        saveKeyInIndexedDB("sales", res);
                    } else {
                        verifiedIfSaleCollectionIsReallyEmpty(q);
                    }

                }, err => {
                    console.error(err);
                    if (err.code === "permission-denied") {
                        deleteKey("sales");
                        setSales(null);
                    };
                });
                return () => unsub();
            }

        }
        // eslint-disable-next-line
    }, [saleListener, accessTo]);

    useEffect(() => {
        if (accessTo && saleListener && accountData) {
            if (accessTo.sales) {
                const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/salesStatistics`, "statistics"), (doc) => {
                    const statisticsUpdated = {
                        ...doc.data(),
                        "id": doc.id,
                    };

                    if (doc.data()) {
                        setSalesStatistics(statisticsUpdated);
                        saveKeyInIndexedDB("salesStatistics", statisticsUpdated);
                    }
                }, err => {
                    console.error(err);
                    if (err.code === "permission-denied") {
                        deleteKey("salesStatistics");
                        setSalesStatistics(null);
                    };
                });
                return () => unsub();
            }
        }
        // eslint-disable-next-line
    }, [saleListener, accessTo]);

    async function verifiedIfPurchasesCollectionIsReallyEmpty(q) {
        try {
            const snapshot = await getCountFromServer(q);

            if (!(snapshot.data().count > 0)) {
                setPurchases([]);
                saveKeyInIndexedDB("purchases", []);
            }

        } catch (error) {
            console.warn(error);
        }
    }

    useEffect(() => {
        if (accessTo && purchasesListener && accountData) {
            if (accessTo.purchases) {
                const q = query(
                    collection(firebase.db, `accounts/${accountData.id}/purchases`),
                    orderBy("date", "desc"),
                    limit(30)
                );

                const unsub = onSnapshot(q, (querySnapshot) => {
                    const res = [];

                    querySnapshot.forEach((doc) => {
                        if (doc.data()) {
                            const thisDoc = {
                                ...doc.data(),
                                "id": doc.id,
                            }

                            delete thisDoc.searchKeys;

                            res.push(thisDoc)
                        }
                    });

                    if (res.length > 0) {
                        setPurchases(res);
                        saveKeyInIndexedDB("purchases", res);
                    } else {
                        verifiedIfPurchasesCollectionIsReallyEmpty(q);
                    }

                }, err => {
                    console.error(err);
                    if (err.code === "permission-denied") {
                        deleteKey("sales");
                        setPurchases(null);
                    };
                });
                return () => unsub();
            }

        }
        // eslint-disable-next-line
    }, [purchasesListener, accessTo]);

    useEffect(() => {
        if (accessTo && purchasesListener && accountData) {
            if (accessTo.purchases) {
                const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/purchasesStatistics`, "statistics"), (doc) => {
                    const statisticsUpdated = {
                        ...doc.data(),
                        "id": doc.id,
                    };

                    if (doc.data()) {
                        setPurchasesStatistics(statisticsUpdated);
                        saveKeyInIndexedDB("purchasesStatistics", statisticsUpdated);
                    }
                }, err => {
                    console.error(err);
                    if (err.code === "permission-denied") {
                        deleteKey("purchasesStatistics");
                        setPurchasesStatistics(null);
                    };
                });
                return () => unsub();
            }
        }
        // eslint-disable-next-line
    }, [purchasesListener, accessTo]);

    return (
        <AuthContext.Provider
            value={{
                user,
                userData,
                notifications,
                userPictures,
                accountPictures,
                auth,
                emailVerified,
                accountSelected,
                accessTo,
                adminData,
                accountData,
                customers,
                suppliers,
                shoppingCart,
                shift,
                authorizedUsers,
                invoiceTemplate,
                products,
                sales,
                purchases,
                customersStatistics,
                suppliersStatistics,
                productsStatistics,
                salesStatistics,
                purchasesStatistics,
                adminAccess,
                managerAccess,
                isCartUpdated,
                deleteAllInformation,
                setUser,
                setAuth,
                setEmailVerified,
                setAccountSelected,
                setAccessTo,
                setAdminData,
                setAccountData,
                setUserData,
                setNotifications,
                setUserPictures,
                setAccountPictures,
                setCustomers,
                setSuppliers,
                setShoppingCart,
                setShift,
                setAuthorizedUsers,
                setInvoiceTemplate,
                setProducts,
                setSales,
                setPurchases,
                setCustomersStatistics,
                setSuppliersStatistics,
                setProductsStatistics,
                setSalesStatistics,
                setPurchasesStatistics,
                setProductListener,
                setCustomerListener,
                setSupplierListener,
                setSaleListener,
                setPurchasesListener,
                setAuthorizedUsersListener,
                setInvoiceTemplateListener,
                setAdminAccess,
                deleteKey,
                saveKeyInIndexedDB,
                partiallyEliminateCustomers,
                eliminateAllCustomers,
                setPictureInMemory,
                addToShoppingCart,
                updateProductInShoppingCart,
                updateProductsInShoppingCart,
                updateCustomerInShoppingCart,
                removeFromShoppingCart,
                removeMultipleItemsFromShoppingCart,
                changePriceInShoppigCart,
                changeItemQuantityShoppingCart,
                isItemInShoppingCart,
                deleteShoppingCart,
                setCustomerInShoppingCart,
                removeCustomerFromShoppingCart,
                removeCustomerIfIncludedFromShoppingCart,
                updateShoppingCartInfoFromServer,
                applyDiscountToShoppingCart,
                removeDiscountFromShoppingCart
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;